import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import Icon from 'components/Icon';
import { TransactionModal as Modal } from 'components/Modal';
import { useGetTransferLog } from 'components/Participants/gql';
import { useGetTicket } from 'components/Participants/gql/useGetTicket';
import Spacer from 'components/Spacer';
import Text from 'components/Text';
import { formatPhoneNumber } from 'shared/formatters';
import { DataRow, Header, Heading } from './styles';
import { TransferLogModal } from './transferLogModal';

export const TicketDetailModal = ({
  close,
  ticketId,
  date,
  match,
  location,
}) => {
  const theme = useTheme();

  const [viewTransferLog, setViewTransferLog] = useState(false);
  const [transferLogs, setTransferLogs] = useState([]);

  const [ticket, setTicket] = useState(null);

  const { res } = useGetTicket(ticketId);

  useEffect(() => {
    if (res && res.getSpectatorTicket) {
      setTicket(res?.getSpectatorTicket);
    }
  }, [res]);

  const { data } = useGetTransferLog({
    date,
    pass_id: parseInt(ticketId),
  });

  useEffect(() => {
    if (data) {
      setTransferLogs(data.getTransferLog);
    }
  }, [data]);

  return (
    <Modal
      maxWidth={600}
      isVisible={ticketId}
      close={close}
      hideModal={close}
      noHeader
      modalStyle={{ overflow: 'scroll', padding: 20, paddingBottom: 40 }}
    >
      <Header>
        <Text type="heading">Ticket Information</Text>
        <Icon
          icon="close"
          size={18}
          color={'#fa4616'}
          onClick={() => {
            close();
          }}
        />
      </Header>

      <Spacer size={15} />
      <DataRow>
        <Text
          type="bold"
          color={theme.colors.text.gray}
          inlineStyle={{ width: '35%', marginRight: 8 }}
        >
          Ticket Holder:
        </Text>
        <Text
          type="bold"
          fontWeight="600"
          inlineStyle={{ width: '65%', whiteSpace: 'normal' }}
        >
          {ticket?.holder ? ticket.holder : ''}
        </Text>
      </DataRow>

      <Spacer size={15} />
      <DataRow>
        <Text
          type="bold"
          color={theme.colors.text.gray}
          inlineStyle={{ width: '35%', marginRight: 8 }}
        >
          Ticket Number:
        </Text>
        <Text
          type="bold"
          fontWeight="600"
          inlineStyle={{ width: '65%', whiteSpace: 'normal' }}
        >
          {ticket?.barcode ? ticket.barcode : ''}
        </Text>
      </DataRow>

      <Spacer size={15} />
      <DataRow>
        <Text
          type="bold"
          color={theme.colors.text.gray}
          inlineStyle={{ width: '35%', marginRight: 8 }}
        >
          Ticket Name:
        </Text>
        <Text
          type="bold"
          fontWeight="600"
          inlineStyle={{ width: '65%', whiteSpace: 'normal' }}
        >
          {ticket?.ticket?.name}
        </Text>
      </DataRow>

      <Spacer size={15} />
      <DataRow>
        <Text
          type="bold"
          color={theme.colors.text.gray}
          inlineStyle={{ width: '35%', marginRight: 8 }}
        >
          Ticket Price:
        </Text>
        <Text
          type="bold"
          fontWeight="600"
          inlineStyle={{ width: '65%', whiteSpace: 'normal' }}
        >
          {ticket?.price}
        </Text>
      </DataRow>

      <Spacer size={15} />
      {transferLogs.length > 0 && (
        <div>
          <Heading>
            <Text type="heading">Transfer Details</Text>
          </Heading>
          <Spacer size={15} />
        </div>
      )}
      {transferLogs ? (
        transferLogs.map((transfer, index) => (
          <div
            style={{
              borderBottom:
                transferLogs.length - 1 !== index
                  ? '1px solid #d8d8d8'
                  : 'none',
              width: '90%',
            }}
          >
            <Spacer size={25} />
            <DataRow>
              <Text
                type="bold"
                color={theme.colors.text.gray}
                inlineStyle={{ width: '35%', marginRight: 8 }}
              >
                Ticket Holder:
              </Text>
              <Text
                type="bold"
                fontWeight="600"
                inlineStyle={{ width: '65%', whiteSpace: 'normal' }}
              >
                {transfer.name}
              </Text>
            </DataRow>

            <Spacer size={15} />
            <DataRow>
              <Text
                type="bold"
                color={theme.colors.text.gray}
                inlineStyle={{ width: '35%', marginRight: 8 }}
              >
                Mobile Number:
              </Text>
              <Text
                type="bold"
                color={theme.colors.text.gray}
                inlineStyle={{ width: '65%' }}
              >
                <a
                  href={`tel:${transfer.phone_number}`}
                  style={{
                    color: theme.colors.primary,
                    textDecoration: 'none',
                  }}
                >
                  {formatPhoneNumber(transfer.phone_number)}
                </a>
              </Text>
            </DataRow>
            <Spacer size={15} />
            <DataRow>
              <Text
                type="bold"
                color={theme.colors.text.gray}
                inlineStyle={{ width: '35%', marginRight: 8 }}
              >
                Transfer Date:
              </Text>
              <Text
                type="bold"
                fontWeight="600"
                inlineStyle={{ width: '65%', whiteSpace: 'normal' }}
              >
                {transfer.timestamp}
              </Text>
            </DataRow>

            <Spacer size={15} />
            {/* <Button fontSize={22} onClick={() => setViewTransferLog(true)}>
              View transfer Log
            </Button> */}
          </div>
        ))
      ) : (
        <></>
      )}

      <Spacer size={15} />
      <div>
        <Heading>
          <Text type="heading">Scan / Status Detail</Text>
        </Heading>
        <Spacer size={15} />
      </div>
      {ticket?.scan_log && ticket.scan_log.length ? (
        ticket.scan_log.map((log, index) => (
          <div
            style={{
              borderBottom:
                ticket.scan_log.length - 1 !== index
                  ? '1px solid #d8d8d8'
                  : 'none',
              width: '90%',
            }}
          >
            <Spacer size={25} />
            <DataRow>
              <Text
                type="bold"
                color={theme.colors.text.gray}
                inlineStyle={{ width: '35%', marginRight: 8 }}
              >
                Status:
              </Text>
              <Text
                type="bold"
                fontWeight="600"
                inlineStyle={{ width: '65%', whiteSpace: 'normal' }}
              >
                {log.status === 'in' ? 'Scanned In' : 'Scanned Out'}
              </Text>
            </DataRow>

            <Spacer size={15} />
            <DataRow>
              <Text
                type="bold"
                color={theme.colors.text.gray}
                inlineStyle={{ width: '35%', marginRight: 8 }}
              >
                Type:
              </Text>
              <Text
                type="bold"
                fontWeight="600"
                inlineStyle={{ width: '65%', whiteSpace: 'normal' }}
              >
                {log.type === 'manual' ? 'Manual' : 'Scan'}
              </Text>
            </DataRow>

            <Spacer size={15} />
            <DataRow>
              <Text
                type="bold"
                color={theme.colors.text.gray}
                inlineStyle={{ width: '35%', marginRight: 8 }}
              >
                By:
              </Text>
              <Text
                type="bold"
                fontWeight="600"
                inlineStyle={{ width: '65%', whiteSpace: 'normal' }}
              >
                {log.scanner.formatted_name}
              </Text>
            </DataRow>

            <Spacer size={15} />
            <DataRow>
              <Text
                type="bold"
                color={theme.colors.text.gray}
                inlineStyle={{ width: '35%', marginRight: 8 }}
              >
                Date:
              </Text>
              <Text
                type="bold"
                fontWeight="600"
                inlineStyle={{ width: '65%', whiteSpace: 'normal' }}
              >
                {log.created_at}
              </Text>
            </DataRow>

            <Spacer size={15} />
            {/* <Button fontSize={22} onClick={() => setViewTransferLog(true)}>
              View transfer Log
            </Button> */}
          </div>
        ))
      ) : (
        <div
          style={{
            borderBottom: 'none',
            width: '90%',
          }}
        >
          <DataRow>
            <Text
              type="bold"
              color={theme.colors.text.gray}
              inlineStyle={{ width: '35%', marginRight: 8 }}
            >
              Status:
            </Text>
            <Text
              type="bold"
              fontWeight="600"
              inlineStyle={{ width: '65%', whiteSpace: 'normal' }}
            >
              Not Checked In
            </Text>
          </DataRow>
        </div>
      )}

      <TransferLogModal
        admin={location.pathname.includes('/admin/')}
        match={match}
        isVisible={viewTransferLog}
        transferLogs={transferLogs}
        close={() => {
          setViewTransferLog(false);
        }}
      />
    </Modal>
  );
};
